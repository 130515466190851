<template>
    <v-expansion-panel v-if="$canView(navigation)" @change="showPanel = !showPanel">
        <v-expansion-panel-header>
            <template v-slot:default="{ open }">
                <v-row class="mr-2 my-0">
                    <v-menu 
                        v-if="canExportCSV"
                        offset-y 
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                icon
                                v-bind="attrs"
                                v-on="on"
                                title="Settings"
                                :small="small">
                                <v-icon :small="small">mdi-cog</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item v-if="canExportCSV" @click="exportAsCSV" dense>
                                <v-list-item-icon>
                                    <v-icon :small="small">mdi-file-delimited-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Export To CSV</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <span class="ml-3 my-auto py-auto">{{ label }}</span>
                    <v-spacer />
                    <slot name="settings-right" v-bind:allItems="asyncItems" v-bind:items="filteredItems" />
                    <v-btn
                        v-if="open && canRefresh"
                        class="my-auto"
                        icon
                        small
                        title="Refresh"
                        @click.stop="refresh"
                        key="5">
                        <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="open && showEditButton"
                        class="my-auto"
                        small
                        title="Edit"
                        @click.stop="toggleEditing"
                        key="7">
                        {{ isEditing ? 'Filter' : 'Show All' }}
                    </v-btn>
                </v-row>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-slide-y-transition group>
                <v-row v-if="canSearchLocal || canSearch" dense key="3.3" class="my-0">
                    <v-btn
                        :small="small"
                        icon 
                        @click="showSearch = !showSearch">
                        <v-icon v-if="!showSearch" small>mdi-magnify</v-icon>
                        <v-icon v-else small>mdi-close</v-icon>
                    </v-btn>
                    <v-slide-x-transition>
                        <v-text-field
                            v-if="(canSearch || canSearchLocal) && showSearch"
                            flat
                            dense
                            hide-details
                            hide-no-data
                            solo-inverted
                            small
                            single-line
                            prepend-inner-icon="mdi-close"
                            append-icon="mdi-magnify"
                            label="Find"
                            v-model="searchInput"
                            @click:prepend-inner="searchInput = null"
                            @click:append="refresh" />
                    </v-slide-x-transition>

                    <v-spacer />

                    <v-chip-group
                        v-if="showFilters"
                        v-model="selectedFilters"
                        multiple
                        small>
                        <v-fade-transition hide-on-leave>
                            <v-btn v-if="isFilterChanged"
                                text
                                small
                                @click="refresh">
                                <v-icon small left>mdi-filter</v-icon>Apply
                            </v-btn>
                        </v-fade-transition>
                        <v-chip
                            v-for="(filter, index) in chipFilters"
                            filter
                            outlined
                            small
                            :key="index">
                            {{ filter }}
                        </v-chip>
                    </v-chip-group>
                </v-row>
                <v-list 
                    v-if="isLengthyArray(filteredItems)"
                    dense
                    :height="height"
                    key="3.4"
                    class="overflow-y-auto">
                    <!-- <v-list-item-subtitle v-if="subheader != null">{{ subheader }}</v-list-item-subtitle> -->
                    <template v-for="(item, index) in filteredItems">
                        <slot 
                            name="listItem"
                            v-bind:item="item"
                            v-bind:index="index"
                            v-bind:add="add"
                            v-bind:remove="remove"
                            v-bind:patch="patchLink"
                            v-bind:refreshTracking="refreshTracking"
                            v-bind:isEditing="canAuthEdit">
                            <v-list-item
                                :key="index"
                                dense>
                                <slot 
                                    v-bind:item="item"
                                    v-bind:index="index"
                                    v-bind:add="add"
                                    v-bind:remove="remove"
                                    v-bind:patch="patchLink"
                                    v-bind:refreshTracking="refreshTracking"
                                    v-bind:isEditing="canAuthEdit">
                                    <v-list-item-avatar>
                                        <v-btn
                                            v-if="item.isChanged"
                                            small
                                            :loading="item.loadingCount > 0"
                                            @click="patchLink(item)">
                                            <v-icon small left>mdi-content-save</v-icon> Save
                                        </v-btn>
                                        <v-btn 
                                            v-else-if="(manyToMany && item.link != null) || (!manyToMany && item.manyItem[singleProp] == singleID)"
                                            class="success--text"
                                            icon
                                            :loading="item.loadingCount > 0"
                                            @click="remove(item)">
                                            <v-icon>mdi-check</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-else
                                            class="error--text"
                                            icon
                                            :loading="item.loadingCount > 0"
                                            @click="add(item)">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ manyText ? getNestedValue(item.manyItem, manyText) : item.manyItem }}</v-list-item-title>
                                    </v-list-item-content>
                                </slot>
                            </v-list-item>
                        </slot>
                        <v-divider v-if="dividers" :key="'d' + index" />
                    </template>
                    <slot name="bottom" />
                </v-list>
                
                <v-overlay :value="loadingMsg != null" absolute key="6" class="text-center">
                    <v-progress-circular indeterminate size="32" />
                    <p>{{ loadingMsg }}</p>
                </v-overlay>

                <v-alert v-model="errorMsg" dismissible type="error" key="3">{{ errorMsg }}</v-alert>

            </v-slide-y-transition>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import { getBreakdown, nestedValue } from '~helpers';
import { firstBy } from 'thenby';

export default {
    name: 'BT-Blade-Expansion-Links',
    data: function() {
        return {
            asyncItems: [],
            errorMsg: null,
            isAllLoaded: false,
            isEditing: false,
            isFilterChanged: false,
            isFirstTime: true,
            isLoaded: false,
            itemJSON: null,
            loadingMsg: null,
            measurements: [],
            mParams: { includeDetails: false },
            mProxyID: null,
            originalFilters: [],
            searchString: null,
            searchInput: null,
            selectedFilters: [],
            showPanel: false,
            showSearch: false,
            snapshotItemJSON: null,
        }
    },
    props: {
        adjustParams: {
            type: Function,
            default: null
        },
        canEdit: {
            type: Boolean,
            default: true
        },
        canExportCSV: {
            type: Boolean,
            default: false
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        canSearchLocal: {
            type: Boolean,
            default: true
        },
        canSearch: {
            type: Boolean,
            default: true
        },
        customFilters: { //a list of custom filters which when selected adjust the query parameter
            type: Array,
            default: () => { return [{ filter: 'Empties', onFilter: (list, propName) => { return list.filter(x => nestedValue(x, propName) == null) } }] } //{ filter: String, onFilter: list => { list }, filterFunction: () => { return String }, paramProp: (default = 'query') }
        },
        defaultFilters: { //a list of strings which are names of filters which start as selected
            type: Array,
            default: null
        },
        dividers: {
            type: Boolean,
            default: true
        },
        filterProp: {
            type: String,
            default: null
        },
        headers: {
            type: Array, //{ text, textFilter, value, valueFilter, breakdown, csv, csvArray, csvText, csvFilter, csvBreakdown, csvProductIDProp, hide }
            default: null
        },
        height: {
            type: String,
            default: 'auto'
        },
        itemProperties: {
            type: Array,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        leftIcon: {
            type: String,
            default: null
        },
        linkNavigation: {
            type: String,
            default: null
        },
        linkGetURL: {
            type: String,
            default: '/get/Links/{id}' //{id}
        },
        linkParams: {
            type: Object,
            default: null
        },
        linkPostURL: {
            type: String,
            default: '/post/Links/Add'
        },
        linkRemoveByDelete: { //when removing a link - whether to post or delete
            type: Boolean,
            default: false
        },
        linkRemoveURL: {
            type: String,
            default: '/post/Links/Remove'
        },
        manyNavigation: {
            type: String,
            default: null
        },
        manyProp: {
            type: String,
            default: null
        },
        manyText: {
            type: String,
            default: 'text'
        },
        manyToMany: {
            type: Boolean,
            default: false
        },
        navigation: {
            type: String,
            default: null
        },
        navWidth: {
            type: String,
            default: '350'
        },
        onNewLink: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null //() => { return { }}
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        resetToggle: { //different to refresh toggle because pagination and default filters are all reset
            type: Boolean,
            default: false
        },
        rightIcon: {
            type: String,
            default: null
        },
        searchProps: {
            type: Array,
            default: null
        },
        showFilters: {
            type: Boolean,
            default: true
        },
        singleID: {
            type: String,
            default: null
        },
        singleProp: {
            type: String,
            default: null
        },
        sortProp: {
            type: String,
            default: null
        },
        small: {
            type: Boolean,
            default: true
        },
        startEditing: {
            type: Boolean,
            default: false
        },
        startOpen: {
            type: Boolean,
            default: false
        },
        trackChanges: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        asyncItems: {
            deep: true,
            handler() {
                this.updateChange();
            }
        },
        // isEditing: function(val) {
        //     if (val && !this.isAllLoaded) {
        //         this.pullItems();
        //     }
        // },
        params: function(val) {
            if (JSON.stringify(val) !== JSON.stringify(this.mParams)) {
                this.mParams = val;
            }
        },
        refreshToggle: function() {
            this.refresh();
        },
        resetToggle: function() {
            this.reset();
        },
        showPanel: function(val) {
            if (val && !this.isLoaded) {
                this.pullItems();
            }
        }
    },
    created() {
        if (this.params != null) {
            this.mParams = this.params;
        }
    },
    mounted() {
        this.isEditing = this.startEditing;

        if (this.params != null) {
            this.mParams = this.params;
        }

        if (this.isLengthyArray(this.defaultFilters)) {
            this.defaultFilters.forEach(dFilter => {
                this.selectedFilters.push(this.chipFilters.findIndex(y => y == dFilter));
            })
            this.originalFilters = this.selectedFilters;
        }

        if (this.startOpen) {
            this.showPanel = true;
        }
    },
    computed: {
        canAuthEdit() {
            return this.$canEdit(this.linkNavigation);
        },
        chipFilters() {
            var filterList = [];
            
            if (this.isLengthyArray(this.customFilters)) {
                filterList.push(...this.customFilters.map(x => x.filter));
            }

            return filterList;
        },
        csvItems() { //{ csv: true, () => {}, string,  }
            //return this.headers;
            if (!this.isLengthyArray(this.headers)) {
                return [];
            }
            return this.headers
                //.filter(y => (y.csvText != null || (y.text != null && y.csv !== false)) && (y.csv != undefined || y.csvFilter != undefined))
                .filter(y => ((y.csvText != undefined || y.text != undefined) && y.csv != undefined) || y.csv != undefined || y.csvText != undefined || y.csvFilter != undefined || y.csvBreakdown != undefined || y.csvArray != undefined)
                .map(z => {
                    return Object.assign({}, z, {
                        header: z.csvText != null ? z.csvText : z.text,
                        value: (z.csv === true || z.csv == undefined) ? z.value : z.csv,
                        valueFilter: z.csvFilter,
                        breakdown: z.csvBreakdown === true,
                        csvArray: z.csvArray === true
                    });
                });
        },
        filteredItems() {
            var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            
            if (this.canSearchLocal && this.searchInput != null) {
                l = l.filter(x => this.hasSearch(x.manyItem, this.searchInput, this.searchableProperties));
            }

            if (this.isLengthyArray(this.selectedFilters)) {
                var customFilters = [];

                this.selectedFilters.forEach(selectedIndex => {
                    var sFilter = this.chipFilters[selectedIndex];
                    if (this.isLengthyArray(this.customFilters) && this.customFilters.some(cF => cF.filter == sFilter)) {
                        customFilters.push(this.customFilters.find(cF => cF.filter == sFilter));
                    }
                    // else {
                    //     serverFilters.push(sFilter);
                    // }
                });

                var cFilters = this.customFilters.filter(x => x.onFilter != null);
                cFilters.forEach(f => {
                    l = f.onFilter(l, this.singleProp);
                });
            }
            
            l = l.filter(x => x.manyItem.id != this.singleID);

            if (!this.isEditing) {
                if (this.manyToMany) {
                    l = l.filter(x => !(x.link == null || x.link.id == null)); //this.links.some(l => l[this.manyProp] == x.id));
                        //.map(x => { return Object.assign({}, { manyItem: x, link: this.links.find(l => l[this.manyProp] == x.id)})});
                }
                else {
                    l = l.filter(x => this.getNestedValue(x.manyItem, this.singleProp) == this.singleID);
                        //.map(x => { return Object.assign({}, { manyItem: x, link: null })});
                }
            }

            if (this.isLengthyArray(l) && this.sortProp != null) {
                l.sort(firstBy(x => x.manyItem[this.sortProp]));
            }

            return l;
        },
        isChanged() {
            return this.itemJSON != this.snapshotItemJSON;
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.mProxyID != null) {
                return this.mProxyID;
            }
            // else if (this.proxyIDParam != null && this.bladeData != null && this.bladeData.data != null && this.bladeData.data[this.proxyIDParam] != null) {
            //     return this.bladeData.data[this.proxyIDParam];
            // }
            else if (this.proxyIDParam != null && this.$route.query != null && this.$route.query[this.proxyIDParam] != null) {
                return this.$route.query[this.proxyIDParam];
            }

            return null;
        },
        searchableProperties() {
            return this.searchProps;
        },
        showEditButton() {
            return this.canEdit && this.$BlitzIt.auth.canEdit(this.manyNavigation);
        },
    },
    methods: {
        async add(item) { //many item
            if (this.manyToMany) {
                //var existingLink = item.link; //this.links.find(x => x[this.singleProp] == this.singleID && x[this.manyProp] == item.id);
                if (item.link == null || item.link.id == null) {
                    //add
                    try {
                        item.loadingCount += 1;

                        if (item.link == null) {
                            item.link = { };
                            item.link[this.singleProp] = this.singleID;
                            item.link[this.manyProp] = item.manyItem.id;
                        }
                        
                        var res = await this.$BlitzIt.api.post(this.linkNavigation, item.link, this.proxyCompanyID, this.linkPostURL);
                        if (res.data.data != null) {
                            if (res.data.data.id != null) {
                                item.link.id = res.data.data.id;
                            }
                            if (res.data.data.rowVersion != null) {
                                item.link.rowVersion = res.data.data.rowVersion;
                            }
                        }

                        item.linkJSON = this.createSnapshot(item.link);
                        item.isChanged = false;
                    }
                    catch (err) {
                        this.errorMsg = this.extractErrorDescription(err);
                    }
                    finally {
                        item.loadingCount -= 1;
                    }
                }
            }
            else {
                if (item.manyItem[this.singleProp] != this.singleID) {
                    try {
                        item.loadingCount += 1;

                        var patch = {
                            id: item.manyItem.id,
                        };

                        patch[this.singleProp] = this.singleID;
                        
                        await this.$BlitzIt.store.partialPatch(this.manyNavigation, patch);
                        
                        item.manyItem[this.singleProp] = this.singleID;
                    }
                    catch (err) {
                        this.errorMsg = this.extractErrorDescription(err);
                    }
                    finally {
                        item.loadingCount -= 1;
                    }
                }
            }
        },
        createSnapshot(data) {
            if (data == null) {
                return null;
            }

            var copy = this.copyDeep(data);
            return JSON.stringify(copy);
        },
        async exportAsCSV() {
            //csvIncludeTitle
            if (this.isLengthyArray(this.asyncItems)) {
                var csvKeys = this.csvItems;
                
                if (!this.isLengthyArray(csvKeys)) {
                    csvKeys = Object.keys(this.asyncItems[0]);
                    csvKeys = csvKeys.map(x => { return { header: this.fromCamelCase(x), value: x }; });
                }

                var increments = [];
                if (csvKeys.some(y => y.breakdown === true)) {
                    try {
                        increments = await this.$BlitzIt.store.getAll('stock-increments');
                    }
                    catch (err) {
                        console.log(this.extractErrorDescription(err));
                    }
                }

                var csvList = [];
                this.filteredItems.forEach(aItem => {
                    var newItem = {};
                    var otherLines = [];
                    csvKeys.forEach(csvDNA => {
                        //object
                        var v = null;
                        if (typeof(csvDNA.value) == 'function') {
                            //newItem[csvDNA.header] = csvDNA.value(aItem);
                            v = csvDNA.value(aItem);
                        }
                        else if (typeof(csvDNA.value) == 'string') {
                            v = this.getNestedValue(aItem, csvDNA.value);
                        }

                        if (v != null && csvDNA.valueFilter != null) {
                            v = this.$options.filters[csvDNA.valueFilter](v);
                        }

                        if (csvDNA.csvArray) {
                            if (this.isLengthyArray(v)) {
                                v.forEach(w => {
                                    var otherNewItem = {};
                                    if (csvDNA.breakdown && w.productID != null) {
                                        otherNewItem[csvDNA.header] = `${getBreakdown(w.quantity, this.measurements, increments, w.productID)}, ${w.product.productName}`;
                                        otherLines.push(otherNewItem);
                                    }
                                    else {
                                        otherNewItem[csvDNA.header] = w.toString();
                                        otherLines.push(otherNewItem);
                                    }
                                })
                            }
                        }
                        else {
                            if (csvDNA.breakdown) {
                                var prodProp = csvDNA.csvProductIDProp || 'productID';
                                newItem[csvDNA.header] = getBreakdown(v, this.measurements, increments, aItem[prodProp]);
                            }
                            else {
                                newItem[csvDNA.header] = v;
                            }
                        }
                    });
                    csvList.push(newItem);

                    if (this.isLengthyArray(otherLines)) {
                        otherLines.forEach(l => {
                            csvList.push(l);
                        })
                    }
                    
                    otherLines = [];

                });
                this.generateCSVFile(csvList, 'csvData.csv', csvKeys.map(x => x.header), this.csvIncludeTitle ? this.title : null);
            }
        },
        getParamObj() {
            var paramObj = this.copyDeep(this.mParams);

            if (this.searchString && this.searchString.length > 0) {
                if (this.canSearch) {
                    paramObj.searchString = this.searchString;
                }
            }
            
            if (this.isLengthyArray(this.selectedFilters)) {
                //var serverFilters = [];
                var customFilters = [];
                this.selectedFilters.forEach(selectedIndex => {
                    var sFilter = this.chipFilters[selectedIndex];
                    if (this.isLengthyArray(this.customFilters) && this.customFilters.some(cF => cF.filter == sFilter)) {
                        customFilters.push(this.customFilters.find(cF => cF.filter == sFilter));
                    }
                });

                if (this.isLengthyArray(customFilters)) {
                    var query = '';
                    customFilters.forEach(cFilter => {
                        if (cFilter.paramProp == null || cFilter.paramProp == 'query') {
                            query = query + (query.length > 0 ? 'ANDALSO' : '') + (cFilter.filterFunction());
                        }
                        else {
                            paramObj[cFilter.paramProp] = cFilter.filterFunction();
                        }
                    })

                    if (query.length > 0) {
                        paramObj.query = query;
                    }
                }
            }
            
            if (this.isLengthyArray(this.itemProperties)) {
                paramObj.properties = this.copyDeep(this.itemProperties).toString();
            }

            if (!this.isEditing) {
                if (this.adjustParams != null) {
                    paramObj = this.adjustParams(paramObj, this.singleID);
                }
                else if (this.filterProp != null && this.singleID != null) {
                    paramObj.query = `${this.filterProp}=${this.singleID}`;
                }
            }

            return paramObj;
        },
        async patchLink(item) { //many item
            if (this.manyToMany) {
                try {
                    item.loadingCount += 1;

                    if (item.link != null) {
                        var res = await this.$BlitzIt.store.patch(this.linkNavigation, item.link, this.proxyCompanyID);
                        if (res.rowVersion != null) {
                            item.link.rowVersion = res.rowVersion;
                        }
                    }

                    item.isChanged = false;
                    item.linkJSON = this.createSnapshot(item.link);
                }
                catch (err) {
                    this.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    item.loadingCount -= 1;
                }
                
            }
        },
        async pullItems(refresh = false) {
            if (this.manyNavigation == null || this.mParams == null) {
                return;
            }

            if (!this.isEditing && this.isLoaded || this.isEditing && this.isAllLoaded) {
                if (!refresh) {
                    return;
                }
            }

            try {
                this.loadingMsg = 'Pulling Data';

                var paramObj = this.getParamObj();
                var links = [];

                if (this.manyToMany) {
                    var getURL = this.linkGetURL;
                    if (getURL != null) {
                        getURL = getURL.replace('{id}', `${this.singleID}`);
                    }
                    links = await this.$BlitzIt.store.getAll(this.linkNavigation, this.linkParams, refresh, this.proxyCompanyID, getURL);
                    //links = mTRes.data.data;
                }

                var res = await this.$BlitzIt.store.getAll(this.manyNavigation, paramObj, refresh, this.proxyCompanyID, this.customURL);
                
                res = res.map(x => Object.assign({}, { 
                    manyItem: x, 
                    link: this.manyProp != null ? links.find(l => l[this.manyProp] == x.id) : null, 
                    isChanged: false,
                    loadingCount: 0, 
                    errorMsg: null }));
                
                if (this.onNewLink != null) {
                    res.filter(x => x.link == null).forEach(item => {
                        item.link = this.onNewLink(item.manyItem, this.singleID);
                    })
                }

                if (this.onPullSuccessAsync != null) {
                    res = await this.onPullSuccessAsync(res, refresh); //, this.bladeData);
                }
                // else {
                //     this.asyncItems = res;
                // }

                this.asyncItems = res.map(x => Object.assign(x, { 
                    manyItemJSON: this.createSnapshot(x.manyItem), 
                    linkJSON: this.createSnapshot(x.link) }));
                
                if (this.trackChanges) {
                    this.snapshotItemJSON = this.createSnapshot(this.asyncItems);
                    this.itemJSON = this.snapshotItemJSON;
                }

                this.$emit('fetched', this.asyncItems);
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;

                if (!this.isEditing) {
                    this.isLoaded = true;
                }
                if (this.isEditing) {
                    this.isAllLoaded = true;
                }
            }
        },
        refreshTracking(item) {
            if (this.manyToMany) {
                item.isChanged = item.linkJSON != this.createSnapshot(item.link);
            }
        },
        refresh(refresh = true) {
            this.searchString = this.searchInput;
            this.showSearch = false;
            this.errorMsg = null;
            this.pullItems(refresh);
        },
        async remove(item) {
            if (this.manyToMany) {
                try {
                    item.loadingCount += 1;
                    
                    if (item.link == null) {
                        item.link = { };
                        item.link[this.singleProp] = this.singleID;
                        item.link[this.manyProp] = item.manyItem.id;
                    }

                    if (this.linkRemoveByDelete) {
                        await this.$BlitzIt.api.delete(this.linkNavigation, item.link, this.proxyCompanyID, this.linkRemoveURL);
                    }
                    else {
                        await this.$BlitzIt.api.post(this.linkNavigation, item.link, this.proxyCompanyID, this.linkRemoveURL);
                    }
                    
                    if (this.onNewLink != null) {
                        item.link = this.onNewLink(item.manyItem, this.singleID);
                    }
                    else {
                        item.link = null;
                    }

                    item.isChanged = false;
                    item.linkJSON = this.createSnapshot(item.link);
                }
                catch (err) {
                    this.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    item.loadingCount -= 1;
                }
            }
            else {
                if (item.manyItem[this.singleProp] == this.singleID) {
                    try {
                        item.loadingCount += 1;

                        var patch = {
                            id: item.manyItem.id,
                        };

                        patch[this.singleProp] = null;

                        var res = await this.$BlitzIt.store.partialPatch(this.manyNavigation, patch);

                        item.manyItem[this.singleProp] = null;
                        
                        if (res != null && res.rowVersion != null) {
                            item.manyItem.rowVersion = res.rowVersion;
                        }
                    }
                    catch (err) {
                        this.errorMsg = this.extractErrorDescription(err);
                    }
                    finally {
                        item.loadingCount -= 1;
                    }
                }
            }
        },
        reset() {
            this.searchString = null;
            this.searchInput = null;
            this.$BlitzIt.store.clear(this.manyNavigation);
            this.pullItems(true);
        },
        async toggleEditing() {
            this.isEditing = !this.isEditing;

            if (this.isEditing && !this.isAllLoaded) {
                await this.pullItems();
            }
        },
        updateChange() {
            this.itemJSON = this.createSnapshot(this.asyncItems);
        },
    }
}
</script>